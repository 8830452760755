<template>
    <label class="xs:items-center xs:flex-row flex flex-col gap-x-4 gap-y-2">
        <span class="whitespace-nowrap">
            {{ label }}
        </span>

        <BaseUiSelect
            v-model="selected"
            :options="options"
            color="white"
            size="small"
            square
            class="sim-font-medium max-xs:!pl-0"
            autoselect
        />
    </label>
</template>

<script lang="ts" setup generic="T">
import type { SelectOption } from '@core/app/components/base/ui/select/BaseUiSelect.vue'

const {
    label,
    options,
} = defineProps<{
    label: string
    options: SelectOption<T>[]
}>()

const selected = defineModel<SelectOption<T>['value']>()

</script>

<style lang="scss" scoped>

</style>
